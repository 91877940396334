import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getReminderDetails,
  reminderDataHandle,
} from "../../../Redux/BillingManagement/Action";

const ReminderForm = ({ showPopup, setShowPopup, rowId }) => {
  const [emails, setEmails] = useState("");
  const [dueDate, setDueDate] = useState("");
  // const [dateValue, setDateValue] = useState("");
  const [reminderDaysCount, setReminderDaysCount] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    handleServiceIndividualData();
  }, []); // eslint-disable-line

  // click cancel button close popup
  const handleClose = () => {
    setShowPopup((pre) => pre.set("showPopup", false));
  };

  // callBack
  const IndividualCallBack = (DbData) => {
    setEmails(DbData.reminderEmail);
    setDueDate(DbData.dueDate);
    setReminderDaysCount(DbData.reminderDaysCount);
  };

  // database single row data
  const handleServiceIndividualData = () => {
    dispatch(getReminderDetails(rowId, IndividualCallBack));
  };

  //reminder post data add
  let data = {
    rowId,
    emails,
    dueDate,
    reminderDaysCount,
  };

  // get all data send to redux api call
  const getReminderData = () => {
    dispatch(reminderDataHandle(data));
    setShowPopup((pre) => pre.set("showPopup", false));
  };

  // get email in text field
  const handleChange = (event) => {
    setEmails(event.target.value);
  };

  // due date value
  const handleChangeDueDate = (event) => {
    setDueDate(event.target.value);
  };

  // reminder days value
  const handleReminderDays = (event) => {
    setReminderDaysCount(event.target.value);
  };

  // email validation
  const validateEmails = (emails) => {
    const emailArray = emails.split(",");
    const invalidEmails = emailArray.filter(
      (email) =>
        email.trim() !== "" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.trim())
    );

    return invalidEmails.length === 0;
  };

  const handleBlur = (event) => {
    if (!validateEmails(emails)) {
      event.target.setCustomValidity("Invalid email address");
    } else {
      event.target.setCustomValidity("");
    }
  };

  return (
    <>
      <Dialog
        open={showPopup}
        onClose={() => setShowPopup((pre) => pre.set("showPopup", false))}
      >
        <DialogTitle>Reminder Details</DialogTitle>
        <DialogContent>
          <Typography>
            <Box
              sx={{
                width: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
              }}
            >
              <FormControl>
                <InputLabel htmlFor="name-input">Email </InputLabel>
                <br /> <br />
                <TextField
                  label="multiple email address separated by commas"
                  type="email"
                  value={emails}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!validateEmails(emails)}
                  helperText={!validateEmails(emails) ? "Invalid email" : ""}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor="name-input">Due Date </InputLabel>
                <br /> <br />
                <TextField
                  id="outlined-basic"
                  type="date"
                  variant="outlined"
                  autoComplete="none"
                  value={dueDate}
                  onChange={handleChangeDueDate}
                  className="p-2"
                />
              </FormControl>

              <FormControl>
                <InputLabel htmlFor="name-input">
                  Alert days before dut date
                </InputLabel>
                <br /> <br />
                <TextField
                  type="number"
                  value={reminderDaysCount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Day</InputAdornment>
                    ),
                  }}
                  onChange={handleReminderDays}
                  className="p-2"
                />
              </FormControl>
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={getReminderData} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReminderForm;
