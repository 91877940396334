import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {
  Badge,
  List,
  makeStyles,
  withStyles,
  ListItem,
  Box,
} from "@mui/material";
import ReadNotification from "./ReadNotification";
import { updateNotification } from "../../../Redux/DocumentManagement/Action";
import { useDispatch } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const NotificationShowPopup = ({
  notification,
  NotificationShow,
  setNotificationShow,
}) => {
  const handleClose = () => {
    setNotificationShow(false);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [data, setData] = useState();
  const [readNotification, setReadNotification] = useState(false);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const useStyles = makeStyles((theme) => ({
    msgContent: {
      width: "100%",
      // height:"100%",
      background: "#DCE5EF",
      display: "flex",
      justifyContent: "center",
      fontSize: "14px",
    },
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const handleListItemClick = (data, index) => {
    console.log(data);
    setData(data);
    setSelectedIndex(index);

    value === 0
      ? dispatch(updateNotification(data, () => setReadNotification(true)))
      : setReadNotification(true);
  };

  const classes = useStyles();

  const ListItem = withStyles({
    root: {
      "&$selected": {
        backgroundColor: "#1976D2",
        color: "white",
        borderRadius: "10px",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
      "&$selected:hover": {
        backgroundColor: "#2E3D52",
        color: "white",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
      "&:hover": {
        backgroundColor: "#2E3D52",
        borderRadius: "10px",
        userSelect: "none",
        color: "white",
        cursor: "pointer",
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      },
    },
    selected: {},
  })(ListItem);

  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={NotificationShow}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "350px", // Set your width here
              height: "450px",
              position: "absolute",
              right: "25px",
              top: "90px",
              borderRadius: "10px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ padding: "7px" }}
        >
          Notifications
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ padding: 0 }}
            >
              <Tab
                label="New"
                {...a11yProps(0)}
                sx={{ padding: 0 }}
                style={{ minWidth: "50%" }}
              />
              <Tab
                label="previous"
                {...a11yProps(1)}
                sx={{ padding: 0 }}
                style={{ minWidth: "50%" }}
              />
            </Tabs>
          </Box>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box className={classes.msgContent}>
            <TabPanel value={value} index={0}>
              {notification
                .filter((item) => item.get("status", "") === "New")
                .map((item, i) => {
                  return (
                    <List
                      className={classes.root}
                      sx={{
                        fontSize: "14px",
                        boxShadow: 2,
                        borderRadius: "10px",
                        padding: "0",
                        margin: "10px 0",
                      }}
                    >
                      <ListItem
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(item, i)}
                        sx={{ position: "relative" }}
                      >
                        SENDER : {item.get("sender", "")} <br></br>
                        DESCRIPTION : {item.get("description", "")}
                        <Badge
                          color="error"
                          overlap="circular"
                          badgeContent=" "
                          variant="dot"
                          sx={{ position: "absolute", right: "0", top: "0" }}
                        />
                      </ListItem>
                    </List>
                  );
                })}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {notification
                .filter((item) => item.get("status", "") === "Seen")
                .map((item, i) => {
                  return (
                    <List
                      className={classes.root}
                      sx={{
                        fontSize: "14px",
                        boxShadow: 2,
                        borderRadius: "10px",
                        padding: "0",
                        margin: "10px 0",
                      }}
                    >
                      <ListItem
                        selected={selectedIndex === i}
                        onClick={(event) => handleListItemClick(item, i)}
                        sx={{ position: "relative" }}
                      >
                        SENDER : {item.get("sender", "")} <br></br>
                        DESCRIPTION : {item.get("description", "")}
                      </ListItem>
                    </List>
                  );
                })}
            </TabPanel>
          </Box>
        </DialogContent>
      </BootstrapDialog>
      {readNotification && (
        <ReadNotification
          data={data}
          readNotification={readNotification}
          setReadNotification={setReadNotification}
          setNotificationShow={setNotificationShow}
        />
      )}
    </Box>
  );
};

export default NotificationShowPopup;
