import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  th: {
    background: "#1565C0",
    color: "white",
  },
  content: {
    width: "100%",
    height: "130px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    fontSize: "20px",
    marginBottom: "3px",
  },
  totalAmount: {
    width: "180px",
    height: "50px",
    background: "#1565C0",
    textAlign: "center",
    lineHeight: "50px",
    color: "white",
    fontSize: "17px",
  },
});
