import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import { useDispatch } from "react-redux";
import { getAllType, setData } from "../../../Redux/BillingManagement/Action";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Forms from "./Forms";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import Loader from "../../../LoaderSnackbar/Loader";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  Container: {
    width: "100%",
    height: "calc(100vh - 65px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  BackArrow: {
    marginLeft: "5px",
    fontSize: "17px",
    fontWeight: 500,
  },
});

const CreateForm = () => {
  const loadingStatus = useSelector((state) => state.BillingState).get(
    "loading",
    ""
  );
  const messageStatus = useSelector((state) => state.BillingState).get(
    "message",
    ""
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate("/cloudBilling");
  };

  useEffect(() => {
    dispatch(getAllType(dispatch));
  }, []); // eslint-disable-line

  return (
    <div className="container-fluid">
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />

      <span>
        <IconButton disableRipple onClick={handleClick}>
          <ArrowBackIcon />
          <span className={classes.BackArrow}> Back</span>
        </IconButton>
      </span>

      <Box className={classes.Container}>
        <Forms />
      </Box>
    </div>
  );
};

export default CreateForm;
