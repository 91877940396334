import React from "react";
// import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import * as ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
import { Box } from "@mui/material";
import "./Editor.css";

export const Editors = ({ htmlContent, handleChange }) => {
  console.log(htmlContent, typeof htmlContent);
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {/* <GrammarlyEditorPlugin>
        <CKEditor
          editor={ClassicEditor}
          config={{
            placeholder: "Document Content.",
            removePlugins: ["MediaEmbed"],
          }}
          data={htmlContent}
          onChange={(event, editor) => {
            handleChange(editor);
          }}
        />
      </GrammarlyEditorPlugin> */}
      <div>hello team</div>
    </Box>
  );
};
