import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Card, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  snapGetBilling,
  setData,
  EditBillIDAction,
} from "../../../Redux/BillingManagement/Action";
import { selectSnapBillApi } from "../../../Redux/BillingManagement/Selector";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import Loader from "../../../LoaderSnackbar/Loader";
import { List, Map } from "immutable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditViewPopup from "../ModelPopup/EditAndView";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  BackArrow: {
    marginLeft: "5px",
    fontSize: "17px",
    fontWeight: 500,
  },
  thead: {
    textAlign: "center!important",
    fontSize: "18px!important",
    fontWeight: "500!important",
    textTransform: "uppercase!important",
  },
  tbody: {
    textAlign: "center!important",
  },
});

export default function SnapchatTable() {
  const loadingStatus = useSelector((state) => state.BillingState).get(
    "loading",
    ""
  );
  const messageStatus = useSelector((state) => state.BillingState).get(
    "message",
    ""
  );
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [snapGetBillIndex, setSnapGetBillIndex] = useState(0);
  const classes = useStyles();
  const navigate = useNavigate();
  const SnapGetBillApi = useSelector(selectSnapBillApi);
  console.log("getSnapBillingApi", SnapGetBillApi);
  const orderSnap = SnapGetBillApi.sort((x, y) => {
    return y.get("createdAt", "") > x.get("createdAt", "") ? 1 : -1;
  });
  const handleClick = () => {
    navigate("/cloudBilling");
  };
  // const location = useLocation()
  const dispatch = useDispatch();
  const { id } = useParams();
  // const snapId = new URLSearchParams(location.search).get('id')

  useEffect(() => {
    dispatch(snapGetBilling(id));
  }, [id]); // eslint-disable-line

  const handleView = (index) => {
    setOpen(true);
    setType("View");
    setSnapGetBillIndex(index);
    dispatch(EditBillIDAction(index));
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
      fontSize: "20px",
      fontWight: "600",
      backgroundColor: "#1976D2",
      textTransform: "capitalize",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px 16px",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <div className="container-fluid">
      {open && (
        <EditViewPopup
          open={open}
          setOpen={setOpen}
          type={type}
          value={orderSnap.get(snapGetBillIndex, Map())}
        />
      )}
      {/* {open && <BillingView Billing={SnapGetBillApi.get(snapGetBillIndex, Map())} />} */}
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <Box sx={{ width: "100%", height: "70px" }}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <IconButton disableRipple onClick={handleClick}>
            <ArrowBackIcon />
            <span className={classes.BackArrow}> Back</span>
          </IconButton>
        </Box>
      </Box>

      {SnapGetBillApi.size === 0 ? (
        <div
          style={{
            width: "100%",
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 style={{ color: "#C4C4C4" }}>No snapshot...!</h2>
        </div>
      ) : (
        // <TableContainer component={Paper} sx={{ marginTop: "10px", maxHeight: 400 }}>
        //   <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        //     <TableHead>
        //       <TableRow>
        //         <TableCell className={classes.thead}>No</TableCell>
        //         <TableCell className={classes.thead}>Client Name</TableCell>
        //         <TableCell className={classes.thead}>Cloud Type</TableCell>
        //         <TableCell className={classes.thead}>Service Type</TableCell>
        //         <TableCell className={classes.thead}>Total Cost</TableCell>
        //         <TableCell className={classes.thead}>View</TableCell>
        //         <TableCell className={classes.thead}>date</TableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody>
        //       {orderSnap.map((item, index) => {
        //         return (
        //           <TableRow key={index}>
        //             <TableCell className={classes.tbody}>{index + 1}</TableCell>
        //             <TableCell className={classes.tbody}>{item.get("clientName", "")}</TableCell>
        //             <TableCell className={classes.tbody}>{item.get("cloudType", "")}</TableCell>
        //             <TableCell className={classes.tbody}>
        //               {item.get("service", List()).map((innerItem, innerIndex) => {
        //                 const joining = item.get("service", List()).size !== innerIndex + 1 ? "," : "";
        //                 return innerItem.get("serviceType", "") + joining;
        //               })}
        //             </TableCell>
        //             <TableCell className={classes.tbody}>{item.get("totalCost", 0)}</TableCell>
        //             <TableCell className={classes.tbody}>
        //               <Tooltip title="View" arrow placement="top">
        //                 <IconButton onClick={() => handleView(index)} sx={{ marginRight: "18px" }}>
        //                   <VisibilityIcon sx={{ color: "#1976D2" }} />
        //                 </IconButton>
        //               </Tooltip>
        //             </TableCell>
        //             <TableCell className={classes.tbody}>{moment(new Date(item.get("createdAt", ""))).format("D MMM YYYY , h:mm:ss a")}</TableCell>
        //           </TableRow>
        //         );
        //       })}
        //     </TableBody>
        //   </Table>
        // </TableContainer>

        <Card>
          <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell>Client Name</StyledTableCell>
                  <StyledTableCell>Cloud Type</StyledTableCell>
                  <StyledTableCell>Service Type</StyledTableCell>
                  <StyledTableCell>Total Cost</StyledTableCell>
                  <StyledTableCell>View</StyledTableCell>
                  <StyledTableCell>date</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderSnap.map((item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        {item.get("clientName", "")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.get("cloudType", "")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item
                          .get("service", List())
                          .map((innerItem, innerIndex) => {
                            const joining =
                              item.get("service", List()).size !==
                              innerIndex + 1
                                ? ","
                                : "";
                            return innerItem.get("serviceType", "") + joining;
                          })}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.get("totalCost", 0).toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Tooltip title="View" arrow placement="top">
                          <IconButton
                            onClick={() => handleView(index)}
                            sx={{ marginRight: "18px" }}
                          >
                            <VisibilityIcon sx={{ color: "#1976D2" }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        {moment(new Date(item.get("createdAt", ""))).format(
                          "D MMM YYYY , h:mm:ss a"
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </div>
  );
}
