import React, { useState } from "react";
import {
  Autocomplete,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
// import { List } from "immutable";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const Multiselect = ({
  show,
  handleClose,
  handleClick,
  title,
  options,
  department,
  selected,
}) => {
  const [value, setValue] = useState(selected ? selected : []);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChange = (data) => {
    setValue(data);
  };
  // console.log(value, options, "options", selected);

  return (
    <Dialog open={show} onClose={handleClose} className={"multiSelect"}>
      <DialogTitle>{`Add ${title} to ${department}`}</DialogTitle>
      <DialogContent>
        <form id="add-form" onSubmit={handleClick}>
          <label className="mb-3 fs-5">{title} :</label>
          <Autocomplete
            value={value}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            id="tags-outlined"
            // filterSelectedOptions
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
              <MenuItem {...props}>
                <div className="d-flex">
                  <ListItemIcon>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                  </ListItemIcon>
                  <ListItemText className="mt-2">{option.label}</ListItemText>
                </div>
              </MenuItem>
            )}
            renderInput={(params) => {
              return <TextField {...params} placeholder="Add Member" />;
            }}
            multiple
            onChange={(e, option) => handleChange(option)}
          />
        </form>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={(e) =>
            handleClick(
              value,
              department,
              selected?.map((item) => item._id)
            )
          }
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Multiselect;
