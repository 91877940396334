import { Editor } from "@tinymce/tinymce-react";

export default function CustomCKEditor({ data, handleChange }) {
  return (
    <Editor
      apiKey="dm86mbqgddag241ncwy8rhhzqejsny4bx5mw0z1a115jaodb"
      value={data}
      init={{
        height: 400,
        menubar: false,
        toolbar:
          "undo redo | bold | italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image",
        image_dimensions: false,
        image_class_list: [{ title: "Responsive", value: "editor-img" }],
        content_style: `
          body { font-family: Arial, sans-serif; font-size: 14px; }
          .editor-img { max-width: 400px; height: auto; }
          img { max-width: 400px; height: auto; } // Ensures pasting also applies styles
        `,
        images_upload_handler: (blobInfo) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blobInfo.blob());
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject("Failed to upload image");
          }),
        image_advtab: true,
        image_caption: true,
        object_resizing: true,
        paste_data_images: true,
        automatic_uploads: true,
        file_picker_types: "image",
      }}
      onEditorChange={handleChange}
    />
  );
}
