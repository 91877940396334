import React, { useState } from "react";
import { Button, Paper, Dialog, DialogTitle, IconButton } from "@mui/material";
import "./bioMetric.css";
import UpdateBioMetric from "./updateBioImg";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import AttachmentType from "./AddAttachment";
import { List } from "immutable";

const BioMetric = ({
  setBioOpen,
  bioOpen,
  imgURL,
  id,
  rowUserName,
  setOpenVert,
  setUserData,
  userData,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [existing, setExisting] = useState("");
  const [addAttachment, setAddAttachment] = useState(false);

  const [editOpenImgId, setEditOpenImgId] = useState("");

  const closeBioMetric = () => {
    setUserData((pre) => pre.set("bioOpen", false));
  };

  const editBioMetric = (imgId, Count, exist) => {
    setEditOpen(true);
    setEditOpenImgId(imgId);
    setImageCount(Count);
    setExisting(exist);
  };

  const handleAddAttachment = () => {
    setAddAttachment(true);
  };
  return (
    <div>
      <Dialog
        open={userData.get("bioOpen", false)}
        PaperProps={{ style: { minWidth: "400px" } }}
      >
        <Paper>
          <DialogTitle className="bio-heading">BIO METRIC</DialogTitle>

          <IconButton
            edge="start"
            color="inherit"
            onClick={closeBioMetric}
            aria-label="close"
            className="Cancel"
          >
            <CloseIcon />
          </IconButton>

          <div class="text-center">
            <div>
              {userData.get("imgUrl", List())?.map((link, mapIndex) => (
                <div>
                  <img
                    src={link.imgLink}
                    alt="Your_Image"
                    className="bio-my-img"
                  />
                  <br />
                  <br />
                  <Button
                    variant="outlined"
                    endIcon={<EditIcon />}
                    onClick={() =>
                      editBioMetric(link._id, mapIndex, link.imgLink)
                    }
                    className="Edit"
                  >
                    Edit
                  </Button>
                </div>
              ))}
              {(!userData.get("imgUrl", List()) ||
                userData.get("imgUrl", List()).length === 0) && (
                <Button
                  variant="outlined"
                  endIcon={<EditIcon />}
                  className="Add-attachment"
                  onClick={handleAddAttachment}
                >
                  Add Attachment
                </Button>
              )}
            </div>
            <br />
          </div>
        </Paper>
      </Dialog>
      {editOpen && (
        <UpdateBioMetric
          id={id}
          setEditOpen={setEditOpen}
          rowUserName={userData.get("rowUserName", "")}
          setUserData={setUserData}
          imgURL={userData.get("imgUrl", List())}
          editOpenImgId={editOpenImgId}
          imageCount={imageCount}
          existing={existing}
          setOpenVert={setOpenVert}
        />
      )}
      {addAttachment && (
        <AttachmentType
          addAttachment={addAttachment}
          setAddAttachment={setAddAttachment}
          id={id}
          rowUserName={rowUserName}
          setBioOpen={setBioOpen}
          setOpenVert={setOpenVert}
          setUserData={setUserData}
        />
      )}
    </div>
  );
};

export default BioMetric;
