import { makeStyles, withStyles } from "@mui/styles";
import { AccordionSummary, Dialog, styled } from "@mui/material";

export const IconLeftAccordionSummary = withStyles({
  root: {
    minHeight: 0,
    maxHeight: 30,
  },
})(AccordionSummary);

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
}));

export const useStyles = makeStyles((theme) => ({
  expanded: {
    minHeight: 15,
    maxHeight: 15,
  },
  DialogContent: {
    width: "100%",
  },
  BtnRow: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  Btn: {
    padding: "8px 10px",
    margin: "10px 0px",
  },
  AccordionRow: {
    width: "100%",
    display: "flex",
    gap: "10px",
    fontSize: "20px",
  },
  AccordionCol: {
    width: "50%",
    marginBottom: "10px",
  },
  AccordionLabel: {
    width: "35%",
    fontSize: "14px",
    fontWeight: "bold",
  },
  accordionRoot: {
    maxHeight: "15px",
    fontSize: "14px",
  },
  AccordionLabel2: {
    width: "35%",
    fontSize: "14px",
  },
}));
