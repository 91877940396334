import { useState, useEffect } from "react";
import "./LoginForm.css";
import {
  postRegister,
  getUserRoleData,
  getUserData,
} from "../../Redux/TestCase/Action";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectRoles } from "../../Redux/TestCase/Selector";
import logo from "../../Assets/logo.svg";
import AttendanceRegister from "./attendanceRegister";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { Button, InputLabel } from "@mui/material";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";

const backEndApi = process.env.REACT_APP_BackEndUrl;

function LoginForm({ user, urlMongoId }) {
  const [username, setUsername] = useState("");
  const [camera, setCamera] = useState(false);
  const [closeRegister, setCloseRegister] = useState(true);
  const [openWebcome, setOpenWebcome] = useState(false);
  const [role, setRole] = useState("");
  const [userData, setUserData] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [hideCameraButton, setHideCameraButton] = useState(true);
  const [mongoId, setMongoId] = useState(urlMongoId ? urlMongoId : "");
  const [capturedImages, setCapturedImages] = useState([]);
  const [reminderPicNotification, setReminderPicNotification] = useState(false);
  const message = "Take One More Picture";
  const roles = useSelector(selectRoles)?.filter(
    (item) => item.get("role", "") !== "Admin"
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const callBack = (getData) => {
    setCloseRegister(false);
    setOpenWebcome(true);
    setMongoId(getData._id);
    navigate(`?id=${getData._id}`);
  };

  // validate form data
  const validation = (data) => {
    if (data.img === null) {
      return false;
    } else if (data.username === "") {
      return false;
    } else if (data.role === "") {
      return false;
    } else if (data.email === "") {
      return false;
    } else if (data.phoneNumber === undefined || data.phoneNumber === "") {
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("username", username);
    formData.append("role", role);
    formData.append("email", user.email);
    formData.append("phoneNumber", phoneNumber);

    const data = {
      username,
      role,
      email: user.email,
      phoneNumber,
    };

    if (validation(data)) {
      dispatch(postRegister(formData, callBack));
    } else {
      setUserData("Invalid  Form Data..!");
    }
  };

  useEffect(() => {
    dispatch(getUserRoleData());
    dispatch(getUserData());
    setUsername(user.name);
    if (urlMongoId) {
      setCloseRegister(false);
      setOpenWebcome(true);
      setMongoId(urlMongoId);
    }
  }, []); // eslint-disable-line

  // Handle phone number input change
  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const handleOpenCamera = () => {
    setCamera(true);
  };

  // post images to server
  const handleUploadImagesFile = () => {
    axios
      .post(`${backEndApi}oauth/addAttachment?id=${mongoId}`, {
        userAttachment: capturedImages,
      })
      .then(() => {
        console.log("image posted successfully");
        navigate("/auth/pendingApproval");
      });
  };

  return (
    <div className="registration-page">
      <div className="registration-container">
        <div className="img-container">
          <img src={logo} alt="logo" />
        </div>
        <h1>Register</h1>
        {closeRegister && (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={user.email}
                disabled
              />
            </div>
            <div className="form-group">
              <label htmlFor="username">Name</label>
              <input
                type="text"
                id="username"
                name="username"
                defaultValue={user.name}
                onChange={(event) => setUsername(event.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="role">Select Role:</label>
              <select
                value={role}
                onChange={(event) => setRole(event.target.value)}
                style={{ padding: "5px" }}
                required
              >
                <option value="">Select Role</option>
                {roles &&
                  roles.map((role, index) => (
                    <option key={index} value={role.get("role", "")}>
                      {role.get("role", "")}
                    </option>
                  ))}
              </select>
            </div>
            <div className="phone-Number-div">
              <label className="select-country-label">Phone Number :</label>
              <br />
              <PhoneInput
                country={"eg"}
                enableSearch={true}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
            <p className="error">{userData}</p>
            <button type="submit">Register</button>
          </form>
        )}
        {openWebcome && (
          <div className="div-container">
            {hideCameraButton && (
              <InputLabel className="add-bio-metric-heading-register">
                Add Bio Metric Details
              </InputLabel>
            )}
            {img1 && (
              <div>
                <InputLabel className="first-img-label">
                  First Picture
                </InputLabel>
                <img src={img1} alt="Image1" className="first-img-tag" />
              </div>
            )}
            {img2 && (
              <div>
                <InputLabel className="second-img-label">
                  Second Picture
                </InputLabel>
                <img src={img2} alt="Image1" className="first-img-tag" />
              </div>
            )}
            {img3 && (
              <div>
                <InputLabel className="third-img-label">
                  Third Picture
                </InputLabel>
                <img src={img3} alt="Image3" className="first-img-tag" />
              </div>
            )}
            <br />
            {hideCameraButton ? (
              <Button variant="contained" onClick={handleOpenCamera}>
                Start Camera
              </Button>
            ) : (
              <Button
                onClick={() => handleUploadImagesFile()}
                className="send-attachment-images"
                variant="contained"
              >
                Send <SendIcon className="send-mui-icon" />
              </Button>
            )}

            {!img3 && reminderPicNotification && (
              <p className="show-one-more-pic">{message}</p>
            )}
          </div>
        )}
      </div>
      {camera && (
        <AttendanceRegister
          setCamera={setCamera}
          camera={camera}
          username={username}
          imageCount={imageCount}
          setImageCount={setImageCount}
          setImg1={setImg1}
          setImg2={setImg2}
          setImg3={setImg3}
          setHideCameraButton={setHideCameraButton}
          capturedImages={capturedImages}
          setCapturedImages={setCapturedImages}
          mongoId={mongoId}
          setReminderPicNotification={setReminderPicNotification}
        />
      )}
    </div>
  );
}

export default LoginForm;
