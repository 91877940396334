import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  Chip,
  IconButton,
  TextField,
  Tooltip,
  Autocomplete,
  Card,
  Badge,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fromJS, List } from "immutable";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryIcon from "@mui/icons-material/History";

import {
  setData,
  getAllDocumentList,
  getMyDocumentList,
  deleteDocuments,
  getDocumentsType,
  getNotification,
  getDocumentHistoryByDocumentId,
} from "../../Redux/DocumentManagement/Action";
import {
  selectDocumentList,
  selectMessage,
  selectIsLoading,
  selectAllOption,
  selectNotification,
} from "../../Redux/DocumentManagement/Selector";
import { selectAuthType, selectUser } from "../../Redux/TestCase/Selector";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
// import ViewPopupModel from "./Model/ViewPopup";
import { useSweetAlert } from "../../Utils/Sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import NotificationsSharpIcon from "@mui/icons-material/NotificationsSharp";
import NotificationsPopup from "./Model/NotificationsPopup";
import Pagination from "../../Utils/Pagination";

export default function Document() {
  const loadingStatus = useSelector(selectIsLoading);
  const messageStatus = useSelector(selectMessage);
  const documentList = useSelector(selectDocumentList);
  const allOptions = useSelector(selectAllOption);
  const notification = useSelector(selectNotification);
  const Auth_data = useSelector(selectAuthType).toJS();
  const reduxUserData = useSelector(selectUser).toJS();
  const [paginationSplice, setPaginationSplice] = useState({
    start: 0,
    end: 5,
  });
  // const [ViewPopup, setViewPopup] = useState(false);
  const isMyDocument = window.location.href.split("/").includes("MyDocuments");
  const isDocumentHistory = window.location.href.includes("documentHistory");
  const location = useLocation();
  const documentId = new URLSearchParams(location.search).get("id");
  console.log(isDocumentHistory, "isDocumentHistory");
  // const classes = useStyles();
  const useStyles = makeStyles({
    FilterRow: {
      width: "100%",
      height: "70px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    FilterCell: {
      width: "20%",
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const sweetAlert = useSweetAlert();
  const initialFilter = fromJS({
    DepartmentName: [],
    ProductName: "",
    DocumentName: "",
    DocumentTags: "",
    all: "",
  });
  const [filter, setFilter] = useState(initialFilter);
  console.log(filter);

  const filterDocumentList = documentList
    .filter((item) =>
      filter.get("DepartmentName", List()).size === 0
        ? true
        : filter
            .get("DepartmentName", List())
            .some(
              (innerItem) =>
                innerItem.get("label", "") ===
                item.getIn(["DepartmentName", "label"], "")
            )
    )
    .filter((item) =>
      item
        .getIn(["ProductName", "label"], "")
        .includes(filter.get("ProductName", ""))
    )
    .filter((item) =>
      item.get("DocumentName", "").includes(filter.get("DocumentName", ""))
    )
    .filter((item) =>
      item
        .get("DocumentTags", List())
        .some((item) =>
          item.get("label", "").includes(filter.get("DocumentTags", ""))
        )
    )
    .filter(
      (item) =>
        item
          .getIn(["DepartmentName", "label"], "")
          .includes(filter.get("all", "")) ||
        item
          .getIn(["ProductName", "label"], "")
          .includes(filter.get("all", "")) ||
        item.get("DocumentName", "").includes(filter.get("all", "")) ||
        item
          .get("DocumentTags", List())
          .some((item) => item.get("label", "").includes(filter.get("all", "")))
    );

  const callBack = () => {
    isMyDocument
      ? dispatch(getMyDocumentList(Auth_data?.email))
      : isDocumentHistory
      ? dispatch(getDocumentHistoryByDocumentId(documentId))
      : dispatch(
          getAllDocumentList({
            department:
              reduxUserData?.role === "Admin"
                ? "Admin"
                : reduxUserData?.department
                ? reduxUserData.department
                : [],
            id: reduxUserData?._id,
          })
        );
  };

  const handleDelete = (e, id) => {
    sweetAlert({ id, ApiCall: deleteDocuments, callBack });
  };

  const handleEdit = (e, id) => {
    navigate(`/EditDocument?id=${id}`);
  };
  const handleHistory = (e, id) => {
    navigate(`/documentHistory?id=${id}`);
  };
  const handleClearAll = () => {
    setFilter(initialFilter);
  };
  const handleClick = () => {
    navigate("/CreateDocument");
  };
  const handleView = (e, id) => {
    // setViewPopup(filterDocumentList.get(index, ""));
    navigate(
      `/viewDocument?id=${id}${isDocumentHistory ? "&history=true" : ""}`
    );
  };
  console.log(reduxUserData, reduxUserData._id);
  useEffect(() => {
    isMyDocument
      ? dispatch(getMyDocumentList(Auth_data?.email))
      : isDocumentHistory
      ? dispatch(getDocumentHistoryByDocumentId(documentId))
      : dispatch(
          getAllDocumentList({
            department:
              reduxUserData?.role === "Admin"
                ? "Admin"
                : reduxUserData?.department
                ? reduxUserData.department
                : [],
            id: reduxUserData?._id,
          })
        );
  }, [window.location.href]); //eslint-disable-line

  useEffect(() => {
    dispatch(getDocumentsType());
    dispatch(getNotification(Auth_data?.email));
  }, []); //eslint-disable-line

  // useEffect(() => {
  //   ;
  // }, [Auth_data]); //eslint-disable-line

  const handleFilter = (value, key) => {
    console.log("handleFilter", value, key);
    let newValue = value;
    if (key === "DepartmentName") newValue = fromJS(value);
    else if (key !== "DocumentName" && key !== "all")
      newValue = value?.label ? value?.label : "";
    setFilter((prev) => prev.set(key, newValue));
  };

  const [NotificationShow, setNotificationShow] = useState(false);
  console.log("NotificationShow", NotificationShow);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
      fontSize: "20px",
      fontWight: "600",
      backgroundColor: "#1976D2",
      // top: 73,
      zIndex: 1,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px 16px",
      zIndex: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="container-fluid mt-4">
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <Box sx={{ width: "100%", height: "50px" }}>
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Tooltip
            title="Notifications "
            placement="top"
            arrow
            sx={{ marginRight: "16px" }}
          >
            <IconButton
              onClick={() => {
                setNotificationShow(true);
              }}
            >
              <Badge
                color="error"
                badgeContent={
                  notification.filter(
                    (item) => item.get("status", "") === "New"
                  )?.size
                }
              >
                <NotificationsSharpIcon color="action" />
              </Badge>
            </IconButton>
          </Tooltip>

          <Button
            variant="contained"
            sx={{ marginRight: "10px", background: "#1976D2" }}
            onClick={handleClick}
          >
            + Create New Document
          </Button>
        </Box>
      </Box>
      <Card>
        {!isDocumentHistory && (
          <TableRow className={classes.FilterRow}>
            <TableCell className={classes.FilterCell}>
              <TextField
                label="Document Name"
                variant="outlined"
                size="small"
                fullWidth
                autoComplete="none"
                onChange={(e) => handleFilter(e.target.value, "DocumentName")}
              />
            </TableCell>
            <TableCell className={classes.FilterCell}>
              <Autocomplete
                // disablePortal
                multiple
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                limitTags={1}
                filterSelectedOptions
                getOptionLabel={(option) => option.label}
                id="Department_Name"
                size="small"
                fullWidth
                // className={useStyles.filter}
                value={filter.get("DepartmentName", List()).toJS()}
                options={allOptions
                  .get("Department", List())
                  .map((item) => {
                    return { label: item.get("departmentName", "") };
                  })
                  .toJS()}
                renderInput={(params) => (
                  <TextField {...params} label="Department Name" />
                )}
                onChange={(e, option) => handleFilter(option, "DepartmentName")}
              />
            </TableCell>
            <TableCell className={classes.FilterCell}>
              <Autocomplete
                disablePortal
                size="small"
                fullWidth
                id="Product_Name"
                value={filter.get("ProductName", "")}
                options={allOptions
                  .get("Product", List())
                  .map((item) => {
                    return { label: item.get("productName", "") };
                  })
                  .toJS()}
                renderInput={(params) => (
                  <TextField {...params} label="Product Name" />
                )}
                onChange={(e, option) => handleFilter(option, "ProductName")}
              />
            </TableCell>
            <TableCell className={classes.FilterCell}>
              <Autocomplete
                size="small"
                fullWidth
                disablePortal
                id="Tags_Name"
                value={filter.get("DocumentTags", "")}
                options={allOptions
                  .get("Tags", List())
                  .map((item) => {
                    return { label: item.get("documentTags", "") };
                  })
                  .toJS()}
                renderInput={(params) => <TextField {...params} label="Tags" />}
                onChange={(e, option) => handleFilter(option, "DocumentTags")}
              />
            </TableCell>
            <TableCell className={classes.FilterCell}>
              <TextField
                label="Anything"
                variant="outlined"
                size="small"
                fullWidth
                autoComplete="none"
                onChange={(e) => handleFilter(e.target.value, "all")}
              />
            </TableCell>
            <TableCell className={classes.FilterCell}>
              {/* <Button variant="contained">Clear All</Button> */}
              <Button
                startIcon={<DeleteIcon />}
                sx={{ color: "red" }}
                onClick={handleClearAll}
              >
                Clear All Filter
              </Button>
            </TableCell>
          </TableRow>
        )}
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "500px", zIndex: 0 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Document Name</StyledTableCell>
                <StyledTableCell>Department Name</StyledTableCell>
                <StyledTableCell>Product Name</StyledTableCell>
                <StyledTableCell>Tags</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
                {isMyDocument && <StyledTableCell>Status</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterDocumentList
                .slice(paginationSplice.start, paginationSplice.end)
                .map((item, index) => {
                  return (
                    <StyledTableRow
                      key={index}
                      onClick={(e) => handleView(e, item.get("_id", ""))}
                    >
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>
                        {item.get("DocumentName", 0)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.getIn(["DepartmentName", "label"], "")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {item.getIn(["ProductName", "label"], "")}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className=" d-flex justify-content-start align-items-center">
                          {item
                            .get("DocumentTags", List())
                            .map((innerItem, innerIndex) => {
                              const joining =
                                item.get("label", List()).size !==
                                innerIndex + 1
                                  ? ", "
                                  : "";
                              if (innerIndex > 1) return "";
                              return (
                                <Chip
                                  className="m-1"
                                  label={innerItem.get("label", "") + joining}
                                  color="primary"
                                />
                              );
                            })}{" "}
                          {item.get("DocumentTags", List()).size > 2 && (
                            <Tooltip
                              title={
                                <h1 style={{ fontSize: "15px" }}>
                                  {item
                                    .get("DocumentTags", List())
                                    .map((innerItem, innerIndex) => {
                                      const joining =
                                        item.get("DocumentTags", List())
                                          .size !==
                                        innerIndex + 1
                                          ? ", "
                                          : "";
                                      return (
                                        innerItem.get("label", "") + joining
                                      );
                                    })}
                                </h1>
                              }
                              arrow
                              placement="top"
                            >
                              <div className="text-primary">View All</div>
                            </Tooltip>
                          )}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>
                        <div className="d-flex">
                          <Tooltip title="View" arrow placement="top">
                            <IconButton
                              onClick={(e) =>
                                handleView(e, item.get("_id", ""))
                              }
                              sx={{ marginRight: "4px" }}
                            >
                              <VisibilityIcon sx={{ color: "#1976D2" }} />
                            </IconButton>
                          </Tooltip>
                          {!isDocumentHistory && (
                            <>
                              <Tooltip title="Edit" arrow placement="top">
                                <IconButton
                                  onClick={(e) =>
                                    handleEdit(e, item.get("_id", ""))
                                  }
                                  sx={{ marginRight: "4px" }}
                                >
                                  <EditIcon color="success" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="History" arrow placement="top">
                                <IconButton
                                  onClick={(e) =>
                                    handleHistory(e, item.get("_id", ""))
                                  }
                                  sx={{ marginRight: "4px" }}
                                >
                                  <HistoryIcon sx={{ color: "#1976D2" }} />
                                </IconButton>
                              </Tooltip>
                              {isMyDocument && (
                                <Tooltip title="Delete" arrow placement="top">
                                  <IconButton
                                    onClick={(e) =>
                                      handleDelete(e, item.get("_id", ""))
                                    }
                                  >
                                    <DeleteForeverIcon color="error" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </div>
                      </StyledTableCell>
                      {isMyDocument && (
                        <StyledTableCell
                          className={
                            item.get("status", "") === "draft"
                              ? `text-capitalize bold digi-red`
                              : `text-capitalize bold digi-blue`
                          }
                        >
                          {item.get("status", "")}
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Pagination
        handleChange={setPaginationSplice}
        count={filterDocumentList.size}
      />

      {NotificationShow && (
        <NotificationsPopup
          notification={notification}
          NotificationShow={NotificationShow}
          setNotificationShow={setNotificationShow}
        />
      )}
      {/* {ViewPopup && (
        <ViewPopupModel ViewPopup={ViewPopup} setViewPopup={setViewPopup} />
      )} */}
    </div>
  );
}
