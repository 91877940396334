import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";

export default function PaginationControlled(props) {
  const {
    totalPageCount,
    switchPagination,
    currentPage,
    openEnded = false,
    float,
  } = props;
  const theme = useTheme();
  const useStyles = makeStyles((themes) => ({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
      marginBottom: float ? float : "3em",
      float: "right",
    },
  }));
  const classes = useStyles();
  const [page, setPage] = React.useState(currentPage);

  useEffect(() => {
    setPage(isNaN(currentPage) ? 1 : currentPage);
  }, [currentPage]);

  const handleChange = (event, value) => {
    setPage(value);
    switchPagination(value);
  };

  return (
    <div className={!openEnded && classes.root}>
      <div className="my-2">
        {(totalPageCount > 1 || page !== 1) && (
          <Pagination
            count={totalPageCount}
            page={page}
            color="primary"
            onChange={handleChange}
          />
        )}
      </div>
    </div>
  );
}
