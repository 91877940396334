import React, { useEffect, useState } from "react";
import LoupeIcon from "@mui/icons-material/Loupe";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import MainCategoryTabel from "./MainCategoryTabel";
import { useDispatch, useSelector } from "react-redux";
import {
  CategoryErrorAction,
  categoryPopupAction,
  EditCategoryAction,
  postCategoryTypeApi,
  putCategoryTypeApi,
} from "../../../../Redux/AssetsManagement/Action";
import { fromJS } from "immutable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  form: {
    width: "450px",
  },
  formGroup: {
    width: "100%",
    // height:"78px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    "&:nth-child(2)": {
      alignItems: "start",
    },
    // background:"red"
  },
  tabel: {
    width: "100%",
    height: "250px",
    marginTop: "30px",
  },
});

let assetsType = fromJS({
  assetsType: "",
});

const PopupCategory = () => {
  const classes = useStyles();

  const isopenCategory = useSelector((state) => state.AssetsManagement).get(
    "isopenCategory"
  );

  const EditCategory = useSelector((state) =>
    state.AssetsManagement.get("editCategorys")
  );
  //-----------state---------------->
  let [categoryType, setCategoryType] = useState(assetsType);
  let [error, setError] = useState(false);

  const dispatch = useDispatch();
  //------------------------------------------------------------------->
  const hadleClose = () => {
    dispatch(categoryPopupAction(false));
    dispatch(EditCategoryAction(false));
    dispatch(CategoryErrorAction(false));
    setCategoryType(assetsType);
    setError(false);
  };

  const onChangeCategory = (onChangeValue, key) => {
    categoryType = categoryType.set(key, onChangeValue.target.value);
    setCategoryType(categoryType);
  };

  const handleSubmitType = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!categoryType.get("assetsType", "")) {
      setError(true);
    } else if (EditCategory.get("CategoryIndex", "") !== false) {
      dispatch(
        putCategoryTypeApi(
          EditCategory.get("categoryID", ""),
          categoryType.get("assetsType", ""),
          setError,
          setCategoryType,
          assetsType
        )
      );
    } else {
      dispatch(
        postCategoryTypeApi(categoryType, setError, setCategoryType, assetsType)
      );
    }
  };

  useEffect(() => {
    if (EditCategory.get("CategoryIndex", "") !== false) {
      setCategoryType((prev) =>
        prev.set("assetsType", EditCategory.get("CategoryName", ""))
      );
    }
  }, [EditCategory]); // eslint-disable-line

  return (
    <div>
      <Tooltip title="Create category" placement="top" arrow>
        <IconButton
          onClick={() => {
            dispatch(categoryPopupAction(true));
          }}
        >
          <LoupeIcon />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={isopenCategory}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={hadleClose}>
          Create Category
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component={"form"}
            className={classes.form}
            onSubmit={handleSubmitType}
          >
            <Typography>
              <Box className={classes.formGroup}>
                <TextField
                  error={
                    error && categoryType.get("assetsType", "") === ""
                      ? true
                      : false
                  }
                  id="outlined-basic"
                  label="Category"
                  variant="outlined"
                  autoComplete="none"
                  helperText={
                    error && categoryType.get("assetsType", "") === ""
                      ? "Category field invalid"
                      : ""
                  }
                  value={categoryType.get("assetsType", "")}
                  onChange={(e) => onChangeCategory(e, "assetsType")}
                />
                <Button variant="contained" type="submit">
                  + Category
                </Button>
              </Box>
              <Box className={classes.tabel}>
                <MainCategoryTabel />
              </Box>
            </Typography>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default PopupCategory;
