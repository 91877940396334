import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemText from "@mui/material/ListItemText";
import Header from "./Header";
import { AppBar, DrawerHeader } from "./Styles";

import { NavLink } from "react-router-dom";

import {
  Avatar,
  Collapse,
  ListItemIcon,
  ListItem as MuiListItem,
} from "@mui/material";
import { SidebarData } from "./SideBar/SidebarData";
import { useSelector } from "react-redux";
import { selectUser } from "../../Redux/TestCase/Selector";
import { List as ListImmutable } from "immutable";
import Dlogo from "../../Assets/Dlogo.jpg";
import Breadcrumbs from "./Breadcrumbs";
import { styled } from "@mui/material/styles";

export default function SideNavbar({ open, setOpen }) {
  const drawerWidth = 240;
  const [showDropdown, setShowDropdown] = useState({
    Assets: false,
    Documentation: false,
  });

  const showSideNav = (route) => {
    if (userPageAccess.includes(route) || userRole === "Admin") return true;
    else return false;
  };

  const showSide = [
    "Home",
    "Assets",
    "Attendance",
    "Billing",
    "Support",
    "DB dump",
    "Documentation",
    "TestCase",
  ];

  const normalLink = "";
  const userType = useSelector(selectUser);
  const userRole = userType?.get("role", "");

  const activeLink = "bg-info text-primary ";
  const userPageAccess = userType?.get("pageAccess", ListImmutable());
  const ListItem = styled(MuiListItem)(({ theme }) => ({
    marginTop: "5px",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderRadius: "15px",
    "&.Mui-selected": {
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&.Mui-selected:hover": {
      backgroundColor: "purple",
      color: "white",
      transition: "all 0.88s ease",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&:hover": {
      borderRadius: "15px",
      transition: "all 0.55s ease",
      background:
        "transparent linear-gradient(97deg,#1a7bdc,#56b8ff) 0 0 no-repeat padding-box",
      cursor: "pointer",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  }));

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDropList = (key) => {
    setShowDropdown((previous) => {
      return { ...previous, [key]: !previous[`${key}`] };
    });
  };

  const handleNavigate = (redirect, title) => {
    var RecentTab =
      localStorage.getItem("recent") === null
        ? []
        : JSON.parse(localStorage.getItem("recent"));
    if (title !== "Home") {
      RecentTab = RecentTab?.filter((item) => item.title !== title);
      RecentTab.unshift({
        title: title,
        redirect: redirect,
      });
    }
    if (RecentTab.length > 3) {
      RecentTab.splice(3);
    }
    localStorage.setItem("recent", JSON.stringify(RecentTab));
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        style={{
          background:
            " transparent linear-gradient(97deg,#1a7bdc,#56b8ff) 0 0 no-repeat padding-box",
          zIndex: "999",
        }}
      >
        <Toolbar className="d-flex justify-content-between flex-wrap">
          <div className="d_all_center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Breadcrumbs />
          </div>
          <Header />
        </Toolbar>
      </AppBar>
      <>
        {/* <SidebarMenu setOpen={setOpen} open={open}/> */}
        <Drawer
          sx={{
            width: drawerWidth,

            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              padding: "10px",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader style={{ minHeight: "55px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              }}
            >
              <Avatar
                alt="\"
                src={Dlogo}
                sx={{ border: "3px solid #1976D2" }}
              />
              <div>
                {/* <div
                  style={{
                    fontSize: "10px",
                    fontWeight: "600",
                    color: "#a1a1a1",
                  }}
                >
                  GOOD DAY &#128075;
                </div> */}
                <div style={{ fontSize: "18px", fontWeight: "600" }}>
                  Digival Tool
                </div>
              </div>
            </div>
            {/* <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton> */}
          </DrawerHeader>
          <Divider />

          <List disablePadding sx={{ marginTop: "10px" }}>
            {SidebarData.map((items, i) => {
              if (items.subNav) {
                return (
                  showSideNav(showSide[i]) && (
                    <>
                      <ListItem onClick={() => handleDropList(items.title)}>
                        <ListItemIcon>{items.icon}</ListItemIcon>

                        <ListItemText primary={items.title} />

                        {showDropdown[`${items.title}`] ? (
                          <>{items.iconClosed}</>
                        ) : (
                          <>{items.iconOpened}</>
                        )}
                      </ListItem>
                      {items.subNav.map((item, index) => {
                        return (
                          <Collapse
                            in={showDropdown[`${items.title}`]}
                            timeout="auto"
                            unmountOnExit
                            disablePadding
                          >
                            <NavLink
                              onClick={() =>
                                handleNavigate(item.path, item.title)
                              }
                              to={item.path}
                              style={{ color: "black", textDecoration: "none" }}
                              className={({ isActive }) =>
                                isActive ? activeLink : normalLink
                              }
                            >
                              <ListItem>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.title} />
                              </ListItem>
                            </NavLink>
                          </Collapse>
                        );
                      })}
                    </>
                  )
                );
              } else {
                return (
                  (showSideNav(showSide[i]) || items.title === "Home") && (
                    <NavLink
                      to={items.path}
                      onClick={() => handleNavigate(items.path, items.title)}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem
                        key={i}
                        disablePadding
                        // onClick={() => handleNavigate(items.path, items.title)}
                      >
                        <ListItemIcon>{items.icon}</ListItemIcon>

                        <ListItemText primary={items.title} />
                      </ListItem>
                    </NavLink>
                  )
                );
              }
            })}
          </List>
        </Drawer>
      </>
    </>
  );
}
